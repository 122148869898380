<template>
  <v-container fluid>
    <!-- start of form -->
    <editor
      :seriesData="seriesArray"
      :branchesData="branchData"
      :usersData="allUsersData"
      :deparmentData="departmentsArray"
      :defaultSeries="defaultSeries"
      :docCreate="docCreate"
      :docCopyTo="docCopyTo"
      :setup="setup"
      :tableRows="tableRows"
      :initial="dataItem"
      :tableData="tableData"
      :objectName="objectName"
      :activeObject="activeObject"
      :withPayment="withPayment"
      @data="save"
    ></editor>
    <!-- end of form -->
    <snackbar ref="snackbar"></snackbar>
  </v-container>
</template>

<script>
import Editor from "../_components/create-editor.vue";
import { mapGetters } from "vuex";
import axios from "axios";
export default {
  components: {
    Editor,
  },
  computed: {
    ...mapGetters(["user"]),
    branchData() {
      return this.$store.state.branches;
    },
    allUsersData() {
      return this.$store.state.userdata;
    },
    loggedInUser() {
      return this.$store.state.user;
    }
  },
  data: () => ({
    dataItem: { parent: false, document_lines: [] },
    docCreate: true,
    docCopyTo: false,
    hasAttachments:false,
    tenantData: {},
    editMode: false,
    currencies: [],
    setup: {},
    defaultSeries: {},
    seriesArray: [],
    departmentsArray: [],
    usersArray: [],
    saleDocs: [],
    objectName: null,
    record: {},
    tableData: [],
    tableRows: [],
    activeObject: null,
    withPayment: null,
  }),
  watch: {
    "$route.params.objectId": {
      handler: "formSettings",
      immediate: true,
    },
  },
  methods: {
    save(data) {
      const self = this;
      data.ObjType = this.activeObject;
      const formData = new FormData();
      const files = data.oats || [];

      if (files.length > 0) {
        self.hasAttachments = true;
        files.forEach((file, f) => {
          const m_file = file.attachmedDetails;
          formData.append("files[" + f + "]", m_file);
        });
      }
      const url = "/inventory-transactions";

      this.$store
        .dispatch("post", { url, data })
        .then((res) => {
          if (res.ResultCode == 1200) {
            if (self.hasAttachments){
              self.$refs.snackbar.show("Uploading Documents", "green");
              formData.append("ObjType", res.ResponseData.newObjType);
              formData.append("DocEntry", res.ResponseData.id);
              this.uploadAttachments(formData,res.ResponseData);
              this.$store.commit("loader", false);
              self.$router.push(`/inventory/transactions/${self.activeObject}`);
            }else {
              this.$store.commit("loader", false);
              self.$router.push(`/inventory/transactions/${self.activeObject}`);
              self.postToSap(res.ResponseData)
            }
          } else {
            this.$store.commit("loader", false);
            self.$refs.snackbar.show(res.ResultDesc, "red");
          }
        })
        .catch((err) => {
          console.log(err);
          this.$store.commit("loader", false);
        });
    },

    uploadAttachments(formData,resData) {
      // const id = this.activeObject;
      const url = "/upload_attachments";
      const self = this;
      this.$store
        .dispatch("post", { url, data: formData })
        .then((res) => {
          if (res.ResultCode == 1200) {
            // this.$store.commit("loader", false);
            self.$refs.snackbar.show("Document Submitted successfully, Posting Data to SAp","green");
            // self.$router.push(`/sales/doc/${id}`);
            self.postToSap(resData);
            // self.$router.push(`/inventory/transactions/${id}`);
          } else {
            this.$store.commit("loader", false);
            self.$refs.snackbar.show(res.ResultDesc, "red");
          }
        })
        .catch((err) => {
          console.log(err);
          this.$store.commit("loader", false);
        });
    },

    postToSap(resData){
      const self = this;
      const docEntry = resData.id;
      const objectId = resData.ObjType;
      this.$store
          .dispatch("get", `/integrator/transactions/${objectId}?docEntry=${docEntry}`)
          .then((res) => {
            const ExternalErpUrl = self.loggedInUser.oadm.ExternalErpUrl;
            axios
                .post(
                    ExternalErpUrl,
                    res,
                    this.options
                )
                .then((resp) => {
                  console.log(resp)
                  // this.$store.commit("loader", false);
                  self.$router.push(`/inventory/transactions/${objectId}`);
                });
          })
          .catch((err) => {
            console.log(err);
          });
    },

    saveDraft(data) {
      const url = "/drafts";
      const self = this;
      this.$store
        .dispatch("post", { url, data })
        .then((res) => {
          console.log(res, "r");
          // self.$refs.snackbar.show("Item group created", "green");
          self.$router.push("/sales/ar-invoice");
        })
        .catch((err) => {
          console.log(err, "err");
          //  console.log(err);
        });
    },
    formSettings(objectId) {
      this.activeObject = objectId;
      const self = this;
      this.loading = true;
      this.$store
        .dispatch("get", `/form_settings/${objectId}`)
        .then((res) => {
          self.setup = res;
          self.tableRows = res.tabs[0].tableRows;
          self.defaultSeries = res.DfltSeries;
          self.seriesArray = res.Series;
          self.usersArray = res.users;
          self.departmentsArray = res.departments;
          self.BPLId = res.BPLId;
          self.loading = false;
        })
        .catch((err) => {
          console.log(err, "error");
        });

      // set object Name
      this.$store
        .dispatch("get", `/doc_model/${objectId}`)
        .then((res) => {
          self.objectName = res.DocumentName;
        })
        .catch((err) => {
          console.log(err);
        });
      // end

      if (this.activeObject !== 13) {
        this.withPayment = "N";
      }
      if (this.activeObject == 13) {
        this.withPayment = "Y";
      }
    },
    getCurrencies() {
      const self = this;
      this.loading = true;
      this.$store
        .dispatch("get", `/currency`)
        .then((res) => {
          self.currencies = res;
          self.loading = false;
        })
        .catch((err) => {
          console.log(err);
        });
    },

    getData() {
      const self = this;
      this.$store
        .dispatch("get", `/branches`)
        .then((res) => {
          if (res.ResultCode == 1200) {
            self.userDefaults = res.ResponseData;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },

  created() {
    localStorage.removeItem("docType");
    localStorage.setItem("docType", "I");
    this.$store.commit("loader", false);
    // this.getCurrencies();
  },
};
</script>